import React, { Component } from "react"
import Collapsible from "react-collapsible"
//import MidTown from "../components/data.json"

class postBuilder extends Component {
  render() {
    console.log()
    const listItems = this.props.locations.map((
      post //TODO add images...
    ) => (
      <Collapsible trigger={post.name} classParentString="midtownLunch">
        <div className="row" style={{ display: "inline" }}>
          <a id="menu" href={post.website} style={{ float: "left" }}>
            Menu
          </a>
          <p>{post.formatted_address}</p>
        </div>
        <p>Review: {post.review}</p>
      </Collapsible>
    ))
    return <ul>{listItems}</ul>
  }
}
export default postBuilder

import React from "react"
import Maps from "../components/googleM"
import "../styles/global.css"
import Collapsible from "react-collapsible"
import Post from "../components/postBuilder.jsx"
import MidTown from "../components/data.json"
import Helmet from "react-helmet"
//require("bootstrap")
//import "react-bootstrap"
//import { Container, Row, Col } from "react-bootstrap"
//import "bootstrap/dist/css/bootstrap.min.css"

const IndexPage = () => (
  <div>
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Helmet>
    <div>
      <div className="row">
        <h1 id="head" className="col">
          Jimmy's Eats
        </h1>
      </div>

      <div className="row">
        <div id="map" className="col-6">
          <Maps locations={MidTown} />
        </div>
        <div id="posts" className="col-6">
          <Collapsible trigger="About Me">
            <p>Welcome to my food blog built with Gatsby.</p>
            <p>Food good for the soul, and sometimes the waisteline too!</p>
          </Collapsible>
          <Collapsible trigger="Midtown Cheap(ish) Eats">
            <p id="sectionIntro">
              These are some of the many places I frequent for lunch and
              sometimes after work. I hope that you will enjoy them as much as I
              do.
            </p>
            <Post locations={MidTown} />
          </Collapsible>
          <Collapsible trigger="Happy Hour :)">
            <p>Coming Soon</p>
          </Collapsible>
          <Collapsible trigger="Wine and Dine Them!">
            <p>Coming Soon</p>
          </Collapsible>
        </div>
      </div>
    </div>
  </div>
)

export default IndexPage
